<template>
    <div class="PublishTab-container">
        <!--发布方式TAB按钮：...-->
        <div class="way-tab">
            <div class="wt-label fl">{{ $t('myProject.releaseMode') }}</div>
            <!--        我发布的项目-->
            <div class="wt-area fl">
                <div :class="{active:tabIndex===0}" @click="changeTab(0)" class="wt-btn ">{{ $t('myProject.all') }}
                </div>
                <div :class="{active:tabIndex===1}" @click="changeTab(1)" class="wt-btn">{{
                    $t('myProject.publicRelease') }}
                </div>
                <div :class="{active:tabIndex===2}" @click="changeTab(2)" class="wt-btn">
                    {{ $t('myProject.vipPrivateRecommendation') }}
                </div>
                <div :class="{active:tabIndex===3}" @click="changeTab(3)" class="wt-btn">
                    {{ $t('myProject.multiPersonCommunication') }}
                </div>
            </div>
        </div>
        <!--列表（我发布的项目）-->
        <UlList1 :list='list'/>
        <div style="text-align: center;padding-top: 20px" v-if="list.length==0">{{$t('myWallet.noDataAvailable')}}
            <!--暂无数据--></div>

        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: center"
                v-if="total!==0">
            <slot>
                <div class="jump-page">
                    <span>{{ $t('myProject.goTo') }}</span>
                    <input type="text" v-model.number="goPageNum">
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>
    import UlList1 from "@/components/my_project/my_published_projects/UlList1";
    import {myPublishedProject} from '@/api/myProjectApi/publishedApi'
    import {pageMixin} from '@/mixins';
    import Loading from '@/components/loading'

    export default {
        name: "PublishTab",
        data() {
            return {
                tabIndex: 0,//发布类型(0:全部；1：公开推荐；2：VIP私密推荐)
                isLoading: false,
            };
        },
        mixins: [pageMixin],
        components: {
            UlList1,
            Loading
        },
        created() {
            this.myPushProject();//我发布的项目
        },
        methods: {
            changeTab(index) {
                this.tabIndex = index;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.myPushProject();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.myPushProject();
            },
            //  我发布的项目列表
            myPushProject() {
                let formData = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    tabType: this.tabIndex,
                    token: localStorage.getItem('token')
                }
                this.isLoading = true;
                myPublishedProject(formData).then((res) => {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                    this.isLoading = false;
                })
            },
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.myPushProject();
            }
        }
    }
</script>

<style scoped>
    .way-tab {
        overflow: hidden;
        background: #FAFAFA;
        border-top: 1px solid #E5E5E5;
        margin-top: 39px;
    }

    .wt-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 47px;
        color: #949494;
        margin: 0 16px;
        display: inline-block;
    }

    .wt-area {
        overflow: hidden;
    }

    .wt-btn {
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 16px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #000000;
        text-align: center;

        margin-right: 16px;
        margin-top: 8px;
        float: left;
    }

    .active {
        border: 1px solid #707070;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
